import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from "./404.module.scss"

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className={styles.container}>
        <h1>Página no encontrada</h1>
        <br></br>
        <p>
          Lo sentimos, pero la página que estabas buscando se la comió nuestro
          perro...
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
